
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Image from '$docs/components/Image'
import WalletAndEarningUi from './images/ux_best_practices_01_wallet_and_earning_ui.jpg'
import WalletAndEarningUi2x from './images/ux_best_practices_01_wallet_and_earning_ui@2x.jpg'
import KnowYourResponsibility from './images/ux_best_practices_02_know_your_responsibility.jpg'
import KnowYourResponsibility2x from './images/ux_best_practices_02_know_your_responsibility@2x.jpg'
import WalletAddress from './images/ux_best_practices_03_wallet_address.jpg'
import WalletAddress2x from './images/ux_best_practices_03_wallet_address@2x.jpg'
import FiatCurrencyDisplay from './images/ux_best_practices_04_fiat_currency_display.jpg'
import FiatCurrencyDisplay2x from './images/ux_best_practices_04_fiat_currency_display@2x.jpg'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Data Unions UX best practices`}</h1>
    <p>{`If you build a Data Union app or integrate Data Unions into an existing app, it needs to incorporate a simple crypto wallet because your users will receive revenue in DATA token. Below are a collection of features, suggestions and good usability practices you may want to include in your app.`}</p>
    <h3>{`Balance, available balance and cumulative earnings`}</h3>
    <p>{`Because users can withdraw tokens from their primary Ethereum address to another wallet, showing both current total and cumulative earnings is a good idea, so users can know their historical earnings as well as the current balance.`}</p>
    <Image src={WalletAndEarningUi} highResSrc={WalletAndEarningUi2x} alt="Balance, available balance and cumulative earnings" figCaption="Example wallet and earnings UI for a Data Union app" mdxType="Image" />
    <h3>{`Private key handling`}</h3>
    <p>{`Because users may want to use another wallet for managing their funds, we strongly recommend offering private key access to all your users. Without this, they do not really have control of their own funds. When handling private keys, you need to allow users to access the private keys, but you should generally avoid displaying them on screen and keep them hidden from view.`}</p>
    <p>{`You also need to inform your users about their responsibility for security, because it is entirely up to them. When onboarding, a good practice is to break the users flow of just blindly clicking onwards through key information. Using disabled, conditional buttons that require explicit agreement from the user will not solve all your problems, but might help to prevent some users from ignoring important warnings.`}</p>
    <Image src={KnowYourResponsibility} highResSrc={KnowYourResponsibility2x} alt="Private key handling" mdxType="Image" />
    <h3>{`Wallet addresses`}</h3>
    <p>{`Providing clear access and copy functionality to the user's wallet address is always good practice, so they can watch it via other applications, and check it with external services. When pasting addresses into wallet address fields, informing the user that the contents of the field match the clipboard, and also show the ETH & ERC-20 tokens held by that address helps to reduce user stress and avoids the common practice of double-checking addresses by copy-pasting them as text.`}</p>
    <Image src={WalletAddress} highResSrc={WalletAddress2x} alt="Wallet addresses" mdxType="Image" />
    <h3>{`Fiat currency display`}</h3>
    <p>{`Allowing your users to switch currency views between the DATA token and a standard fiat currency like USD is a good idea. If they use your app over a long time they might get accustomed to thinking in DATA, but don’t assume they will.`}</p>
    <Image src={FiatCurrencyDisplay} highResSrc={FiatCurrencyDisplay2x} alt="Fiat currency display" mdxType="Image" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;