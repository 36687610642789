
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom'
import docsLinks from '$shared/../docsLinks'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Connecting applications to a Broker node`}</h1>
    <p>{`The Broker node ships with interface plugins, which can be used to publish and subscribe to data from applications over off-the-shelf protocols HTTP, Websocket, and MQTT.`}</p>
    <h2>{`Configuration`}</h2>
    <p>{`The plugins are enabled and configured in the Broker config file. To generate a config file and enable the plugins you need, you can use the `}<Link to={docsLinks.installingABrokerNode} mdxType="Link">{`config wizard`}</Link>{`.`}</p>
    <h2>{`Authentication`}</h2>
    <p>{`The plugins expose ports and API endpoints which can be used to publish and subscribe to data using the identity of the Broker node. You will want to secure those ports, either by setting up a firewall and restricting access to the ports based on IP, or configuring API keys that only allow access if the API key is provided.`}</p>
    <p>{`The API keys can be configured in the Broker config file, in a top-level field `}<inlineCode parentName="p">{`apiAuthentication`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "network": ...
    "plugins": ...
    "apiAuthentication": {
        "keys": ["my-very-secret-key"]
    }
}
`}</code></pre>
    <p>{`By knowing any of the correct API keys, the applications are granted access to the node. The API keys are passed by the application in slightly different ways depending on which protocol is used.`}</p>
    <p>{`Note that the API keys grant access to publishing and subscribing via your node, while the node's private key grants access to your node's identity and assets. Never confuse API keys and the node's private key, and never send the private key over the internet.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`The configuration and usage of each of the plugins is documented `}<a parentName="p" {...{
        "href": "https://github.com/streamr-dev/network-monorepo/blob/main/packages/broker/plugins.md"
      }}>{`here`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;