export const SM = 576

export const MD = 768

export const LG = 992

export const XL = 1200

export const REGULAR = 400

export const MEDIUM = 500

export const TOOLBAR_SHADOW = '0 0 10px rgba(0, 0, 0, 0.1)'

export const MONO = "'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace"

export const SANS = "'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif"
