
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Data signing and verification`}</h1>
    <p>{`Authenticity and integrity of events published on a stream can be guaranteed with digital signatures. Every stream's metadata has a boolean `}<inlineCode parentName="p">{`requiresSignedData`}</inlineCode>{` that can be set by the stream's owner to let subscribers know whether they should expect signed or unsigned events.`}</p>
    <p>{`Every message published to a stream has six fields that uniquely identify this message across time, all streams and all publishers:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`streamId`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`streamPartititon`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`timestamp`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sequenceNumber`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`publisherId`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`msgChainId`}</inlineCode></li>
    </ul>
    <p>{`More details about these fields can be found in the `}<a href="https://github.com/streamr-dev/streamr-specs/blob/master/PROTOCOL.md" target="_blank" rel="noopener noreferrer">{`protocol specification`}</a>{`. All together they form the message ID. They must be signed along with the actual message  content to resist against replay attacks. So the payload to be signed for every message by every publisher is the following.`}</p>
    <pre><code parentName="pre" {...{}}>{`payload = streamId + streamPartition + timestamp + sequenceNumber + publisherId + msgChaindId + content
`}</code></pre>
    <p>{`The signing algorithm follows the convention described `}<a href="https://github.com/ethereum/EIPs/blob/master/EIPS/eip-712.md" target="_blank" rel="noopener noreferrer">{`here`}</a>{`. The secp256k1 ECDSA algorithm is applied on the keccak256 hash of a string derived from the challenge text.`}</p>
    <pre><code parentName="pre" {...{}}>{`signature = sign(keccak256("\\x19Ethereum Signed Message:\\\\n" + len(payload) + payload)))
`}</code></pre>
    <p>{`On the recipient side, every subscriber needs to verify signed events. If a received event is unsigned, the subscriber accepts the event if and only if the stream's boolean flag `}<inlineCode parentName="p">{`requiresSignedData`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`false`}</inlineCode>{`. The signature verification is done in three steps:`}</p>
    <ol>
      <li parentName="ol">{`The subscriber extracts from the event and the signature the Ethereum address that signed the message (using the EC recover operation).`}</li>
      <li parentName="ol">{`Check that the address recovered in step 1 matches the address defined by `}<inlineCode parentName="li">{`publisherId`}</inlineCode></li>
      <li parentName="ol">{`Check that this `}<inlineCode parentName="li">{`publisherId`}</inlineCode>{` belongs to the set of valid publishers for that stream by querying the `}<inlineCode parentName="li">{`api/v1/streams/\${id}/publishers`}</inlineCode>{` endpoint.`}</li>
    </ol>
    <p>{`Both signature computation and verification are implemented in the JavaScript and Java SDKs.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;