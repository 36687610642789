
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom'
import docsLinks from '$shared/../docsLinks'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Joining and parting members`}</h1>
    <h3>{`Member joins`}</h3>
    <p>{`A member can join a Data Union by calling `}<inlineCode parentName="p">{`join`}</inlineCode>{` using the Streamr Client library. This call is answered by a centralized gatekeeping service, currently run by Streamr, but can be replaced by your own gatekeeping service. Joining does not incur a gas fee if the default client settings are used. `}</p>
    <p>{`The typical way for a device or member to join the Data Union is via shared secrets. See `}<Link to={docsLinks.creatingADataUnion} mdxType="Link">{`Creating a Data Union`}</Link>{` for details on creating a shared secret. Calling `}<inlineCode parentName="p">{`join`}</inlineCode>{` with a valid secret will allow the member to join immediately:`}</p>
    <pre><code parentName="pre" {...{}}>{`const dataUnion = await streamr.getDataUnion(dataUnionAddress)
await dataUnion.join(secret)
`}</code></pre>
    <h3>{`Removing a member`}</h3>
    <p>{`To remove (part) members, please refer to the `}<a href="https://github.com/streamr-dev/network-monorepo/blob/main/packages/client/README.md#admin-functions" target="_blank" rel="noopener noreferrer">{`Data Union Admin API documentation`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;