
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'
import routes from '$routes'
import docsLinks from '$shared/../docsLinks'
import docsStyles from '$docs/components/DocsLayout/docsLayout.pcss'
import Image from '$docs/components/Image'
import SignInWithEth from './images/gs_eth_sign_02_desktop.jpg'
import SignInWithEth2x from './images/gs_eth_sign_02_desktop@2x.jpg'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Identity`}</h1>
    <p>{`Your Ethereum account is your Streamr account and identity - there's no email registration, simply `}<Link to={routes.auth.login()} mdxType="Link">{`login`}</Link>{` with your Web3 Ethereum wallet such as `}<a href="https://metamask.io" target="blank">{`Metamask`}</a>{`.`}</p>
    <p>{`Streamr supports `}<a href="https://ens.domains" target="_blank" rel="noopener noreferrer">{`ENS`}</a>{` names to allow streams to have human readable names, for example `}<inlineCode parentName="p">{`mydomain.eth/traffic/helsinki`}</inlineCode>{`. More on that in the `}<Link to={docsLinks.streams} mdxType="Link">{`Streams`}</Link>{` section.`}</p>
    <h3>{`Connect your Ethereum Account`}</h3>
    <p>{`The easiest way to get started is through the free and open source Chrome browser extension, Metamask. Metamask is also available on Android and Apple mobile devices. The explainer video below will help you understand how to use Metamask.`}</p>
    <div className={docsStyles.playerWrapper}>
    <ReactPlayer controls className={docsStyles.reactPlayer} url="https://www.youtube.com/watch?v=ZIGUC9JAAw8" width="100%" height="100%" mdxType="ReactPlayer" />
    </div>
    <p>{`Once you have your Ethereum account on Metamask, you can connect it to Streamr. A popup will ask you to sign a message with your Ethereum account to prove ownership, click `}<strong parentName="p">{`Sign`}</strong>{`.`}</p>
    <Image src={SignInWithEth} highResSrc={SignInWithEth2x} alt="Ethereum sign in" mdxType="Image" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;