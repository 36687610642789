
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import cx from 'classnames'
import { Link } from 'react-router-dom'
import routes from '$routes'
import Image from '$docs/components/Image'
import Warning from '$docs/components/Warning'
import { CodeSnippet } from '@streamr/streamr-layout'
import docsLinks from '$shared/../docsLinks'
import StreamEditorPreview from './images/tutorials_pub_sub_01_desktop.jpg'
import StreamEditorPreview2x from './images/tutorials_pub_sub_01_desktop@2x.jpg'
import Postman from './images/tutorials_pub_sub_02_desktop.jpg'
import Postman2x from './images/tutorials_pub_sub_02_desktop@2x.jpg'
import { ClientSub, ClientPub, InputsAndOutputs } from './code/tutorials.js'
import docsStyles from '$docs/components/DocsLayout/docsLayout.pcss'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Publish and Subscribe`}</h1>
    <p>{`Bring your data to Streamr in the way that works best for you — as a JS library within your app, or from any application/langauge via MQTT, HTTP or Websocket.`}</p>
    <h3>{`Publish and Subscribe via JS library`}</h3>
    <p>{`The Streamr client library wraps a Streamr light node for publishing and subscribing to data, and contains convenience functions for creating and managing streams. You can insert a node into any application where JavaScript runs, including the browser.`}</p>
    <p>{`Checkout the docs section for `}<Link to={docsLinks.usingALightNode} mdxType="Link">{`Using a light node`}</Link>{`.`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://www.npmjs.com/package/streamr-client" target="_blank" rel="noopener noreferrer">Streamr Client NPM</a>
      </li>
      <li parentName="ul">
        <a href="https://github.com/streamr-dev/network-monorepo/blob/main/packages/client/README.md" target="_blank" rel="noopener noreferrer">Streamr Client Github</a>
      </li>
    </ul>
    <h3>{`Publish and subscribe via MQTT, HTTP or Websocket`}</h3>
    <p>{`You will need to run a Broker node and interface to it with either MQTT, HTTP or Websocket. Checkout the docs section for `}<Link to={docsLinks.connectingApplications} mdxType="Link">{`Connecting Applications`}</Link>{`. `}</p>
    <h3>{`Data preview`}</h3>
    <p>{`Inside the `}<Link to={routes.streams.index()} mdxType="Link">{`Core app`}</Link>{`, you can select the stream and monitor the data preview - your published messages will appear here in real-time.`}</p>
    <Image src={StreamEditorPreview} highResSrc={StreamEditorPreview2x} alt="Stream Editor Preview" mdxType="Image" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;