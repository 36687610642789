
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import routes from '$routes'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`How To Contribute`}</h1>
    <p>{`Contributions to these docs, or any of our open source projects is warmly welcomed!`}</p>
    <p>{`You can find others developing on Streamr, as well as get in touch with the Streamr core contributors: `}<a target="_blank" rel="noopener noreferrer" href={routes.community.discord()}>{`Discord`}</a></p>
    <p>{`You can also reach out to the core team over `}<a parentName="p" {...{
        "href": "mailto:contact@streamr.com"
      }}>{`email`}</a>{`! Here is a `}<a href="https://github.com/streamr-dev/core-frontend/blob/master/app/src/docs/docsEditingGuide.md" target="_blank" rel="noopener noreferrer">{`guide on contributing to the Docs`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;