
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import docsLinks from '$shared/../docsLinks'
import routes from '$routes'
import { Link } from 'react-router-dom'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Overview`}</h1>
    <p>{`Streamr is an open source, crowdfunded, decentralized platform for real-time data. At its heart is the `}<a target="_blank" rel="noopener noreferrer" href={routes.site.discover.network()}>{`Streamr Network`}</a>{`, which transports `}<Link to={docsLinks.streams} mdxType="Link">{`streams`}</Link>{` of messages from data publishers to subscribers, appearing to the user as a global publish/subscribe messaging service.`}</p>
    <p>{`It is similar in functionality to message queues, enterprise messaging systems, MQTT brokers, Apache Kafka, or pub/sub cloud messaging services. The key features of such systems, including Streamr, are:`}</p>
    <ul>
      <li parentName="ul">{`Publishing messages in order to make them available to subscribers`}</li>
      <li parentName="ul">{`Subscribing to messages in order to process them as they occur`}</li>
      <li parentName="ul">{`Storing messages for later use`}</li>
    </ul>
    <p>{`You can create and manage your streams using the `}<Link to={routes.core()} mdxType="Link">{`Core`}</Link>{` user interface. In addition to the Network, the Streamr stack includes a `}<Link to={docsLinks.marketplace} mdxType="Link">{`Marketplace`}</Link>{` for data sharing and monetization.`}</p>
    <p>{`Streamr utilizes a companion blockchain, currently `}<a target="_blank" rel="noopener noreferrer" href="https://www.ethereum.org">{`Ethereum`}</a>{` and `}<a target="_blank" rel="noopener noreferrer" href="https://www.xdaichain.com">{`xDai`}</a>{`. The blockchain provides users with secure digital identities, enables direct transfers of value in the form of a Streamr-specific cryptocurrency `}<a target="_blank" rel="noopener noreferrer" href={routes.site.discover.dataToken()}>{`DATA`}</a>{`, and provides a shared ground truth for the network nodes.`}</p>
    <h3>{`Why use Streamr?`}</h3>
    <p>{`The big difference to comparable data frameworks is that Streamr aims to be decentralized and peer-to-peer. Similar to cloud services, you get on-demand scalability, minimal up-front investment, and benefit from economies of scale. Unlike cloud services, there's no vendor lock-in, no monopolies, no proprietary code, and no need to trust a third party with your data. Decentralized networks are also secure by design, more resilient to attacks and disruptions due to their distributed and peer-to-peer nature. They also benefit from network effects— as adoption grows, the above points become stronger, a shared standard is formed across industries, and a lot of useful content becomes available from a single source.`}</p>
    <p>{`Instead of a centralized party, the Network is run by its community of users, and it relies on cryptography to remove the need for trust. This creates a permissionless and neutral network for real-time data, which is highly suitable for use cases such as data sharing, data monetisation, and the `}<a target="_blank" rel="noopener noreferrer" href={routes.misc.decentralizedWeb()}>{`decentralized web`}</a>{`, extending the capabilities of the underlying internet without compromising its open and decentralized nature.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;