
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom'
import docsLinks from '$shared/../docsLinks'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Use Cases`}</h1>
    <p>{`The Streamr network opens up a world of innovation by connecting unstoppable pub/sub real-time data transport to the vibrant ecosystem of decentralized applications.`}</p>
    <p>{`For example, a stream can receive data from many sources (or publishers), and there can be several listeners who subscribe to a stream in many-to-one, one-to-many, or many-to-many applications. These are just a few examples. There is virtually no limit on the kind, format, or quantity of data you can feed into Streamr, even digital images, streaming video, or other domain-specific data could be pushed into a stream.`}</p>
    <h3>{`Data Monetisation`}</h3>
    <p>{`Buy and sell access to ethically sourced data streams using `}<Link to={docsLinks.dataUnions} mdxType="Link">{`Data Unions`}</Link>{`. Data Unions are a huge step forward in creating the fair data economy based on ethical first principles. The Streamr Network is idealogically aligned to this cause by providing a permissionlesss and community owned alternative to the data brokering monopolies that exist today.`}</p>
    <h3>{`DevOps`}</h3>
    <p><a parentName="p" {...{
        "href": "https://blog.streamr.network/collecting-metrics-data-from-decentralized-systems"
      }}>{`Gather metrics from decentralized systems`}</a>{` such as blockchain networks. Getting detailed real-time information about the state of nodes in your network is incredibly useful to diagnosing issues and improving performance.`}</p>
    <h3>{`Gaming`}</h3>
    <p>{`The Network is capable of being the real-time networking layer of massive multiplayer games. Light nodes can be run in the browser making for truly decentralized multiplayer experiences.`}</p>
    <h3>{`IoT messaging`}</h3>
    <p>{`Publish and broadcast messages from any kind of internet-connected device.`}</p>
    <p>{`Some common use cases include:`}</p>
    <ul>
      <li parentName="ul">{`Sensory readings of speed and orientation`}</li>
      <li parentName="ul">{`Geolocation`}</li>
      <li parentName="ul">{`Ambient temperature and humidity `}</li>
      <li parentName="ul">{`Social media messages`}</li>
      <li parentName="ul">{`Stock market events`}</li>
      <li parentName="ul">{`Mobile ad impressions`}</li>
    </ul>
    <h3>{`Networking middleware`}</h3>
    <p>{`Transfer metadata for VoIP or WebRTC signalling.`}</p>
    <h3>{`Blockchain networking`}</h3>
    <p>{`DDoS resistant block transfer or broadcast signed transactions with low and predictable latency.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;