
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom'
import Image from '$docs/components/Image'
import Warning from '$docs/components/Warning'
import docsLinks from '$shared/../docsLinks'
import MessagePartitioningImage from './images/streams_partioning_01_desktop.jpg'
import MessagePartitioningImage2x from './images/streams_partioning_01_desktop@2x.jpg'
import StreamAndPartitionsImage from './images/streams_partioning_02_desktop.jpg'
import StreamAndPartitionsImage2x from './images/streams_partioning_02_desktop@2x.jpg'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Partitioning`}</h1>
    <p>{`To be precise, when messages are published to a stream, they are actually published to a `}<strong parentName="p">{`partition`}</strong>{` within that stream. Partitions can be understood as sub-streams, or in other words, parts of a stream. One partition per stream is the default, which is sufficient for streams with moderate rates of data (approx. less than 100 msg/sec).`}</p>
    <p>{`When a stream needs to handle large data rates, partitions are used for `}<em parentName="p">{`sharding`}</em>{` of the data. Sharding data simply means dividing a large volume of messages to multiple partitions, a bit like a large river can split the same amount of water to multiple smaller branches. Each partition shares the general properties of the parent stream, such as name, description, and user permissions, but the partitions behave independently when it comes to delivering and storing data in the Streamr Network, which allows for scalability.`}</p>
    <Image src={MessagePartitioningImage} highResSrc={MessagePartitioningImage2x} alt="Message partitioning" mdxType="Image" />
    <p>{`Partitions also enable subscribers to scale horizontally: a user consuming data from a stream could load balance the messages over a number of consuming processes, up to the number of partitions. So if a stream has 5 partitions, the user could start up to 5 independent subscribers on separate physical machines, with each subscriber receiving different messages (each process subscribes to a unique partition).`}</p>
    <p>{`Partitions are identified by a number which starts from zero. For example, a stream with only one partition contains the partition `}<inlineCode parentName="p">{`0`}</inlineCode>{` and all of the stream's data flows through this partition. A stream with 5 partitions has partitions `}<inlineCode parentName="p">{`0`}</inlineCode>{` through `}<inlineCode parentName="p">{`4`}</inlineCode>{` - here the stream's data is distributed across these 5 partitions. The data publisher is free to choose the target partition for each message. If no partition is specified, the data goes to partition 0.`}</p>
    <p>{`While the data publisher is free to choose a target partition for a message arbitrarily, a common approach is to utilize a `}<em parentName="p">{`partition key`}</em>{`. A partition key is a value chosen from the data which is used to determine the partition of the message. For example, a customer ID could be used as a partition key in an application that publishes customer interactions to a stream. This way, all messages from a particular customer always go to the same partition. This is useful because it keeps all the events related to a particular customer in a single and known partition instead of spreading them over all partitions.`}</p>
    <Image src={StreamAndPartitionsImage} highResSrc={StreamAndPartitionsImage2x} alt="Stream and Partitions" mdxType="Image" />
    <p>{`The `}<inlineCode parentName="p">{`publish`}</inlineCode>{` methods in the Streamr client usually take an optional `}<inlineCode parentName="p">{`partitionKey`}</inlineCode>{` parameter. For example:`}</p>
    <pre><code parentName="pre" {...{}}>{`streamr.publish(stream, message, timestamp, partitionKey)
`}</code></pre>
    <p>{`Internally, the library maps the `}<inlineCode parentName="p">{`partitionKey`}</inlineCode>{` to a partition number using a hash function (modulo the number of partitions in the stream):`}</p>
    <pre><code parentName="pre" {...{}}>{`partition = hash(partitionKey) % numberOfPartitions
`}</code></pre>
    <Warning mdxType="Warning">
      <p>{`At the time of writing, there are some limitations with multi-partition streams: The number of partitions can only be set when creating streams via the API, and the number cannot be changed afterwards.`}</p>
    </Warning>
    <p>{`Currently partitions don’t have well-defined rate limits, but future versions of the P2P network may enforce limits. For now, we recommend not exceeding around 100 msg/sec/partition.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;