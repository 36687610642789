
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import cx from 'classnames'
import { Link } from 'react-router-dom'
import Image from '$docs/components/Image'
import StreamStorage from './images/stream_storage.png'
import docsStyles from '$docs/components/DocsLayout/docsLayout.pcss'
import streamsStyles from '$docs/components/Pages/Streams/streamsStyles.pcss'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Data storage`}</h1>
    <p>{`The data in the Streamr Network is stored in storage nodes. You can enable data storage on your streams to retain historical data in one or more geographic locations of your choice. You can also choose how long to store your stream’s historical data before auto-deletion.`}</p>
    <Image src={StreamStorage} alt="Stream storage" mdxType="Image" />
    <p>{`By default, storage is not enabled and you must opt-in by selecting a storage node inside the Core UI. To programmatically do this, please refer to the `}<a href="https://github.com/streamr-dev/network-monorepo/tree/main/packages/client#storage" target="_blank" rel="noopener noreferrer">{`Streamr Client readme`}</a></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;