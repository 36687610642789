
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Glossary`}</h1>
    <h4>{`Broker node`}</h4>
    <p>{`Broker nodes are the miners in the Streamr Network. They are nodes that constantly monitor Bounties requesting data relaying support in return for DATA tokens.`}</p>
    <h4>{`Core app`}</h4>
    <p>{`A user interface for creating and managing streams. `}<a parentName="p" {...{
        "href": "https://github.com/streamr-dev/core-frontend"
      }}>{`GitHub Repo`}</a>{`.`}</p>
    <h4>{`Data Union (DU)`}</h4>
    <p>{`A Data Union framework provides a way to collect and package an individuals's real-time data together with others' with rich consent and while distributing a fair share of the revenue back to the users when someone pays to access it.`}</p>
    <h4>{`Data Union Admin (DU Admin)`}</h4>
    <p>{`The DU Admin creates the Data Union smart contract and is responsible for creating a fair and transparent means for anyone to join the Data Union with the clear intention to monetize the data they choose to share. The DU Admin typically takes a percentage cut of data sales to maintain the upkeep of the Data Union and promote the dataset to data marketplaces.`}</p>
    <h4>{`Data Union Members (DU Member)`}</h4>
    <p>{`Data Union members publish their data to data streams inside a Data Union that they have joined with with rich consent. In return they typically expect rewards, usually in the form of cryptocurrency.`}</p>
    <h4>{`Data Union Operator (DU Operator)`}</h4>
    <p>{`Otherwise known as the Data Union Admin - creates the Data Union smart contract and is responsible for creating a fair and transparent means for anyone to join the Data Union with the clear intention to monetize the data they choose to share. The DU Admin typically takes a percentage cut of data sales to maintain the upkeep of the Data Union and promote the dataset to data marketplaces.`}</p>
    <h4>{`Gap fill`}</h4>
    <p>{`Gap fill is a the mechanism of requesting resends of missing messages in a message chain to help achieve delivery completeness. Messages in Streamr streams are chained, allowing for gap detection (detecting missing messages). For example, the client may receive the following sequence (simplified):`}</p>
    <ul>
      <li parentName="ul">{`Message 1: Previous message was null`}</li>
      <li parentName="ul">{`Message 2: Previous message was Message 1`}</li>
      <li parentName="ul">{`Message 5: Previous message was Message 4`}</li>
    </ul>
    <p>{`The client can now detect that they missed one or more messages that came after Message 2 but before Message 5. They do a "gap fill" to fill the gap, i.e. request a resend of messages from Message 2 (exclusive) up to Message 4 (inclusive).`}</p>
    <h4>{`Light node`}</h4>
    <p>{`Light nodes are bundled inside an application that uses Streamr to publish and/or subscibe to streaming data. Light nodes are not extendable like Broker nodes and cannot 'mine' other streams for DATA tokens.`}</p>
    <h4>{`Stream`}</h4>
    <p>{`A stream is simply a sequence of data points in time, i.e. an append only log. This is semantically equivalent to `}<em parentName="p">{`topics`}</em>{` in traditional pub/sub networks. `}</p>
    <h4>{`Storage node`}</h4>
    <p>{`A Streamr network node that subscribes to data with a commitment to store the data and make it available for retrieval requests.`}</p>
    <h4>{`Topology`}</h4>
    <p>{`Arrangement on nodes that participate in the dissemination of messages inside a stream.`}</p>
    <h4>{`Tracker`}</h4>
    <p>{`Trackers are a type of Streamr node that build and maintain peer to peer stream topologies.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;