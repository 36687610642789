
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import cx from 'classnames'
import { Link } from 'react-router-dom'
import routes from '$routes'
import docsLinks from '$shared/../docsLinks'
import Image from '$docs/components/Image'
import StreamListing from './images/core_streams_01_desktop_border.jpg'
import StreamListing2x from './images/core_streams_01_desktop_border@2x.jpg'
import StreamDetails from './images/core_streams_02_desktop.jpg'
import StreamDetails2x from './images/core_streams_02_desktop@2x.jpg'
import StreamConfigure from './images/core_streams_03_desktop.jpg'
import StreamConfigure2x from './images/core_streams_03_desktop@2x.jpg'
import StreamPreview from './images/core_streams_04_desktop.jpg'
import StreamPreview2x from './images/core_streams_04_desktop@2x.jpg'
import docsStyles from '$docs/components/DocsLayout/docsLayout.pcss'
import streamsStyles from '$docs/components/Pages/Streams/streamsStyles.pcss'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Managing your streams`}</h1>
    <p>{`Streamr Core provides you with the tools to create, edit, share and delete your streams as well as view and inspect the streams you have subscribed to on the Marketplace.`}</p>
    <p>{`Head over to the `}<Link to={routes.streams.index()} mdxType="Link">{`Streams page`}</Link>{` to see an overview of your subscribed streams. The list includes streams you have created and streams that you have subscribe to on the Marketplace.`}</p>
    <Image border src={StreamListing} highResSrc={StreamListing2x} alt="Stream Listing" mdxType="Image" />
    <p>{`The stream listing page displays all the streams the current user has the right to publish or subscribe to. Clicking on a stream will open the stream editor. You may only edit streams that you have created or have explicit permission to edit.`}</p>
    <h3>{`Stream status`}</h3>
    <p>{`Traffic light indicators can show you at a glance when data was last pushed to each stream. `}</p>
    <ul>
      <li parentName="ul">{`Green: The stream is active `}</li>
      <li parentName="ul">{`Grey: The stream is inactive`}</li>
      <li parentName="ul">{`Red: There is a problem with the stream`}</li>
    </ul>
    <p>{`The criteria for an inactive stream is defined in the stream editor.`}</p>
    <h3>{`Editing a stream`}</h3>
    <p>{`The stream editor provides basic tools for setting up, inspecting and editing your stream. Below is a run-down of each stream editor section.`}</p>
    <h3>{`Details`}</h3>
    <p>{`The details section provides basic information about your stream.`}</p>
    <ul>
      <li parentName="ul">{`The stream name is used to identify your stream in the Core app.`}</li>
      <li parentName="ul">{`The stream description can be used to offer more information about your stream and the kind of data it contains.`}</li>
      <li parentName="ul">{`The stream ID is the unique and immutable identifier for your stream.`}</li>
    </ul>
    <Image src={StreamDetails} highResSrc={StreamDetails2x} alt="Stream Details" mdxType="Image" />
    <h3>{`Configure`}</h3>
    <p>{`In this section you can configure stream fields and their data types. All fields have a name and a data type. The Autodetect feature can be used to automatically deduce fields from the stream's last received message. It is possible to manually correct the data types if automatic detection is not suitable for your stream.`}</p>
    <Image src={StreamConfigure} highResSrc={StreamConfigure2x} alt="Stream Configure" mdxType="Image" />
    <p>{`You can also optionally toggle on the requirement for all messages to be signed to enforce a level of verification if you choose.`}</p>
    <h3>{`Preview`}</h3>
    <p>{`This section is used to preview data flowing into the stream. Use the live inspector to make sure your stream is receiving data and it is correctly formatted.`}</p>
    <Image src={StreamPreview} highResSrc={StreamPreview2x} alt="Stream Preview" mdxType="Image" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;