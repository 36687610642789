
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Network Explorer`}</h1>
    <p>{`The `}<a target="_blank" rel="noopener noreferrer" href="https://streamr.network/network-explorer">{`Network Explorer`}</a>{` is an application that brings visibility to the stream topologies inside the Streamr Network.`}</p>
    <p>{`The network is highly dynamic, with a regular cast of broker nodes, but also a constantly revolving number of light nodes appearing and disappearing. Being able to explore it helps node runners inspect their nodes and diagnose any issues.`}</p>
    <p>{`The Network Explorer allows users to see three key metrics for the entire network: `}</p>
    <ul>
      <li parentName="ul">{`Traffic (messages per second)`}</li>
      <li parentName="ul">{`Bandwidth (MB/s)`}</li>
      <li parentName="ul">{`Latency in MS (averaged across all nodes in the topology). `}</li>
    </ul>
    <h3>{`Node search`}</h3>
    <p>{`You can search for areas, say all nodes in Helsinki, streams by path name or description, as well as specific nodes by generated name or Ethereum address. `}</p>
    <h3>{`Stream topology exploration`}</h3>
    <p>{`By searching or selecting a stream, the nodes participating in the stream overlay will be shown in both the results list, and on the geo map. Selecting a node in this list gives you access to all the metrics, and as the full tokenomics rolls out in Tatum milestone, will also give access to the node’s Ethereum address, and link out to its transaction history on Etherscan.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;