export default {
    docs: '/docs',
    welcome: '/docs/welcome',
    learn: '/docs/learn',
    overview: '/docs/learn/overview',
    identity: '/docs/learn/identity',
    useCases: '/docs/learn/use-cases',
    tokenomics: '/docs/learn/data-tokenomics',
    networkExplorer: '/docs/learn/network-explorer',
    glossary: '/docs/learn/glossary',
    howToContribute: '/docs/learn/how-to-contribute',
    streamrNetwork: '/docs/streamr-network',
    introToStreamrNetwork: '/docs/streamr-network/intro-to-streamr-network',
    usingALightNode: '/docs/streamr-network/using-a-light-node',
    installingABrokerNode: '/docs/streamr-network/installing-broker-node',
    updatingABrokerNode: '/docs/streamr-network/updating-broker-node',
    connectingApplications: '/docs/streamr-network/connecting-applications',
    mining: '/docs/streamr-network/mining',
    streams: '/docs/streams',
    introToStreams: '/docs/streams/intro-to-streams',
    creatingStreams: '/docs/streams/creating-streams',
    managingYourStreams: '/docs/streams/managing-your-streams',
    publishAndSubscribe: '/docs/streams/publish-and-subscibe',
    accessControl: '/docs/streams/access-control',
    storage: '/docs/streams/storage',
    dataSigningAndVerification: '/docs/streams/data-signing-and-verification',
    endToEndEncryption: '/docs/streams/end-to-end-encryption',
    partitioning: '/docs/streams/partitioning',
    marketplace: '/docs/marketplace',
    introToMarketplace: '/docs/marketplace/introduction-marketplace',
    creatingDataProducts: '/docs/marketplace/creating-data-products',
    dataUnions: '/docs/data-unions',
    introToDataUnions: '/docs/data-unions/intro-to-data-unions',
    creatingADataUnion: '/docs/data-unions/creating-a-data-union',
    rolesAndResponsibilities: '/docs/data-unions/roles-and-responsibilities',
    walletManagement: '/docs/data-unions/wallet-management',
    joiningAndPartingMembers: '/docs/data-unions/join-and-parting-members',
    withdrawingEarnings: '/docs/data-unions/withdrawing-earnings',
    uxBestPractices: '/docs/data-unions/ux-best-practices',
    dataToken: '/docs/data-token',
    gettingStarted: 'docs/getting-started',
    products: 'docs/products',
}
