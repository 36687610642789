export const GET = 0x01

export const EDIT = 0x02

export const DELETE = 0x04

export const PUBLISH = 0x08

export const SUBSCRIBE = 0x10

export const STARTSTOP = 0x20

export const INTERACT = 0x40

export const SHARE = 0x80
