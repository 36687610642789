// @flow

export const ACTIVE = '#0424ff'

export const SUCCESS = '#2ac437'

export const CAUTION = '#f9cf0d'

export const ERROR = '#ff0f2d'

export const PROCESSING = '#cccccc'

export const DEFAULT = '#323232'
