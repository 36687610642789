
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom'
import docsLinks from '$shared/../docsLinks'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Wallet management`}</h1>
    <p>{`Members are identified by an Ethereum address, which is also their identity in the Streamr ecosystem, as Ethereum keys are used to authenticate to Streamr as well for signing datapoints.`}</p>
    <h3>{`Ethereum key generation & management`}</h3>
    <p>{`Ethereum addresses have an associated private key, which must be kept safe, as it grants access to earned funds. Similarly, losing it means losing funds.`}</p>
    <p>{`Ethereum private keys are just randomly generated 256 bits (32 bytes). They are usually displayed in form of 64 hexadecimal characters. You can use any securely random method of generating those keys, but here’s how to do it using the `}<a href="https://github.com/streamr-dev/network-monorepo/tree/main/packages/client" target="_blank" rel="noopener noreferrer">{`Streamr client`}</a>{` for JavaScript and easily get the associated address too:`}</p>
    <pre><code parentName="pre" {...{}}>{`const wallet = streamr.generateEthereumAccount()
`}</code></pre>
    <p>{`which returns an object of this form:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  address: '0x12345...',
  privateKey: 'acebabe123...'
}
`}</code></pre>
    <p>{`The data-producing application can generate the keys when it is launched for the first time, and store the information locally or the user may connect their existing wallet. The private key should always stay on the device and it should never be sent over the internet.`}</p>
    <p>{`Storing the private key in encrypted form is recommended. One way to approach this is to encrypt it using a user-defined password. If the key is stored encrypted, the key needs to be decrypted when the application starts. The decrypted version should be kept in memory, as it is continuously needed for signing data.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;