
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom'
import Logo from '$shared/components/Logo'
import SvgIcon from '$shared/components/SvgIcon'
import routes from '$routes'
import docsLinks from '$shared/../docsLinks'
import Panels, { ImageWrap } from './Panels'
import Concept, { ImageWrap as ConceptImageWrap } from './Concept'
import UseCase from './UseCase'
import Explore, { ImageWrap as ExploreImageWrap, ExternalLinkWrap } from './Explore'
import Ne from './ne.png'
import Ne2x from './ne@2x.png'
import Du from './du.png'
import Du2x from './du@2x.png'
import iconDATA from './iconDATA.svg'
import iconStreams from './iconStreams.svg'
import iconNodes from './iconNodes.svg'
import next from './next.svg'
import devStack from './devStack.png'
import devStack2x from './devStack@2x.png'
import externalLink from './externalLink.svg'
import Warning from '$docs/components/Warning'
import docsStyles from '$docs/components/DocsLayout/docsLayout.pcss'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Streamr Documentation`}</h1>
    <p>{`Welcome to Streamr, the platform for real-time data on the decentralized web. Explore what you can do with Streamr below.`}</p>
    <Panels mdxType="Panels">
    <Link to={docsLinks.streamrNetwork} mdxType="Link">
        <ImageWrap mdxType="ImageWrap">
            <img alt="" src={Ne} srcSet={`${Ne2x} 2x`} />
        </ImageWrap>
        <div>
            <h4>
                Streamr Network
            </h4>
            <p>
                The Streamr Network is a decentralized pub/sub network for transporting
                real-time data over incentivized WebRTC mesh&nbsp;networks.
            </p>
        </div>
    </Link>
    <Link to={docsLinks.dataUnions} mdxType="Link">
        <ImageWrap mdxType="ImageWrap">
            <img alt="" src={Du} srcSet={`${Du2x} 2x`} />
        </ImageWrap>
        <div>
            <h4>
                Data Unions
            </h4>
            <p>
                Data Unions are a way to collect and package an individuals&apos;s real-time
                data together with others&apos; with rich consent and fair&nbsp;reward.
            </p>
        </div>
    </Link>
    </Panels>
    <hr />
    <h2>{`Key Concepts`}</h2>
    <Concept mdxType="Concept">
    <Link to={docsLinks.streams} mdxType="Link">
        <ConceptImageWrap mdxType="ConceptImageWrap">
            <img src={iconStreams} alt="" />
        </ConceptImageWrap>
        <p>
            <em>Streams</em>
            {' '}
            are sequences of timestamped messages, delivered from publishers to subscribers by the
            Streamr&nbsp;Network.
        </p>
    </Link>
    </Concept>
    <Concept mdxType="Concept">
    <Link to={docsLinks.introToStreamrNetwork} mdxType="Link">
        <ConceptImageWrap mdxType="ConceptImageWrap">
            <img src={iconNodes} alt="" />
        </ConceptImageWrap>
        <p>
            <em>Streamr nodes</em>
            {' '}
            are the access points to the Streamr Network. To connect your application to streams,
            you interface it with a Streamr&nbsp;node.
        </p>
    </Link>
    </Concept>
    <Concept mdxType="Concept">
    <Link to={docsLinks.dataToken} mdxType="Link">
        <ConceptImageWrap mdxType="ConceptImageWrap">
            <img src={iconDATA} alt="" />
        </ConceptImageWrap>
        <p>
            <em>DATA</em>
            {' '}
            is the cryptocurrency which powers the Streamr ecosystem in various ways. It is an
            ERC-20 token on the Ethereum&nbsp;blockchain.
        </p>
    </Link>
    </Concept>
    <h2>{`Start with your use case`}</h2>
    <UseCase mdxType="UseCase">
    <Link to={docsLinks.publishAndSubscribe} mdxType="Link">
        <p>
            I want to publish or subscribe to data from my&nbsp;application.
        </p>
        <img alt="" src={next} />
    </Link>
    </UseCase>
    <UseCase mdxType="UseCase">
    <Link to={docsLinks.managingYourStreams} mdxType="Link">
        <p>
            I want to view and manage my&nbsp;streams.
        </p>
        <img alt="" src={next} />
    </Link>
    </UseCase>
    <UseCase mdxType="UseCase">
    <Link to={docsLinks.networkExplorer} mdxType="Link">
        <p>
            I want to explore the&nbsp;Network.
        </p>
        <img alt="" src={next} />
    </Link>
    </UseCase>
    <UseCase mdxType="UseCase">
    <Link to={docsLinks.mining} mdxType="Link">
        <p>
            I want to mine DATA&nbsp;tokens.
        </p>
        <img alt="" src={next} />
    </Link>
    </UseCase>
    <UseCase mdxType="UseCase">
    <Link to={docsLinks.dataUnions} mdxType="Link">
        <p>
            I want to build a Data&nbsp;Union.
        </p>
        <img alt="" src={next} />
    </Link>
    </UseCase>
    <UseCase mdxType="UseCase">
    <Link to={docsLinks.marketplace} mdxType="Link">
        <p>
            I want to buy and sell real-time&nbsp;data.
        </p>
        <img alt="" src={next} />
    </Link>
    </UseCase>
    <hr />
    <h2>{`Explore the project`}</h2>
    <Explore mdxType="Explore">
    <ExploreImageWrap mdxType="ExploreImageWrap">
        <img alt="" src={devStack} srcSet={`${devStack2x} 2x`} />
    </ExploreImageWrap>
    <ul>
        <li>
            <a href="https://github.com/streamr-dev/examples" rel="noopener noreferrer" target="_blank">
                <div>
                    <h4>
                        Streamr Examples
                    </h4>
                    <p>
                        Streamr code examples and important resources to get you started.
                    </p>
                </div>
                <ExternalLinkWrap mdxType="ExternalLinkWrap">
                    <img src={externalLink} alt="" />
                </ExternalLinkWrap>
            </a>
        </li>
        <li>
            <a href="https://github.com/streamr-dev/network-monorepo/tree/main/packages/cli-tools" rel="noopener noreferrer" target="_blank">
                <div>
                    <h4>
                        Streamr command line tools
                    </h4>
                    <p>
                        Tools for interacting with Streamr from the command line.
                    </p>
                </div>
                <ExternalLinkWrap mdxType="ExternalLinkWrap">
                    <img src={externalLink} alt="" />
                </ExternalLinkWrap>
            </a>
        </li>
        <li>
            <a href="https://github.com/streamr-dev/network-monorepo" rel="noopener noreferrer" target="_blank">
                <div>
                    <h4>
                        Streamr Monorepo
                    </h4>
                    <p>
                        A monorepo containing all the main components of Streamr Network.
                    </p>
                </div>
                <ExternalLinkWrap mdxType="ExternalLinkWrap">
                    <img src={externalLink} alt="" />
                </ExternalLinkWrap>
            </a>
        </li>
    </ul>
    </Explore>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;