
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom'
import docsLinks from '$shared/../docsLinks'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Intro to streams`}</h1>
    <p>{`A stream is simply a sequence of data points in time, i.e. an append only log. This is semantically equivalent to `}<em parentName="p">{`topics`}</em>{` in traditional pub/sub networks.`}</p>
    <p>{`All of the data in the Streamr network is contained inside individual streams. The data may originate, for example from machines on the factory floor, sensors in a smart city, in-house databases or systems, or from commercial streaming data feeds.`}</p>
    <p>{`Streams have the following properties:`}</p>
    <ul>
      <li parentName="ul">{`Any kind of real-time data can be stored in it.`}</li>
      <li parentName="ul">{`The data will always be retrieved in the correct order.`}</li>
      <li parentName="ul">{`The data is persisted on the Streamr network and identifiable by unique ID.`}</li>
    </ul>
    <h3>{`Example stream`}</h3>
    <p>{`Here’s an example of what a small part of a stream could look like. Each row shows one data point, and the columns correspond to the timestamp followed by two data fields, a measurement of the operating temperature and the number of rotations per minute (RPM).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Timestamp`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Temperature`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`RPM`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2019-08-01 11:30:01.012`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`312.56`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3550`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2019-08-01 11:30:02.239`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`312.49`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3549`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2019-08-01 11:30:04.105`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`312.42`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3543`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2016-02-01 11:30:08.122`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`313.21`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3565`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2016-02-01 11:30:11.882`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`317.45`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3602`}</td>
        </tr>
      </tbody>
    </table>
    <section id="stream-data-fields" />
    <h3>{`Stream data fields`}</h3>
    <p>{`A field is a kind of placeholder for an individual piece of data, from a single data point. Each data point contains at least one data field, but you can have as many fields per data point as required.`}</p>
    <p>{`For example, here's a data point in a stock market stream.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "Symbol": "PFFT",
  "EventType": 1,
  "OrderId": 6454321,
  "Direction": "Up",
  "Trade": {"Price": 118.55, "Size": 100},
  "Ask": [
          {"Price": 118.6, "Size": 22500},
          {"Price": 118.65, "Size": 18000},
          {"Price": 118.7, "Size": 13000},
          {"Price": 118.8, "Size": 8000},
          {"Price": 119, "Size": 45000}
          ],
  "Bid": [
          {"Price": 118.5, "Size": 16500},
          {"Price": 118.45, "Size": 11000},
          {"Price": 118.4, "Size": 14200},
          {"Price": 118.2, "Size": 19000},
          {"Price": 118, "Size": 50000}
        ]
}
`}</code></pre>
    <p>{`Given the structure of this data point, the stream's fields could be configured as follows:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Field type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`String`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EventType`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OrderId`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`String`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Trade`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ask`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`List`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bid`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`List`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Data field types`}</h3>
    <p>{`There’s a number of built-in data types that can be used in a stream. These are:`}</p>
    <p><strong parentName="p">{`Number`}</strong>{` is a numeric data type internally stored as a double precision (64-bit) float.`}</p>
    <p><strong parentName="p">{`Boolean`}</strong>{` is a logical data type with two possible values, True and False. In Streamr, a numeric value exactly equal to one represents logical truth. Anything else is interpreted as a logical falsehood.`}</p>
    <p><strong parentName="p">{`String`}</strong>{` is a sequence of zero or more alphabetical characters.`}</p>
    <p><strong parentName="p">{`Object`}</strong>{` is a collection of key-value pairs. Each key is a string, and the value can be of any built-in data type (even another object). Object is equivalent to Java's 'Map'.`}</p>
    <p><strong parentName="p">{`List`}</strong>{` is an ordered collection of zero or more elements. List is equivilent to an array.`}</p>
    <p>{`Field types can be freely mixed in a single data point. And you can freely add new fields to an existing stream; you don’t have to know what fields you might eventually need. A single data point can be of any size within reason, and a stream can grow indefinitely when extended by new data points.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;