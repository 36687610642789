
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom'
import Warning from '$docs/components/Warning'
import docsLinks from '$shared/../docsLinks'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Warning mdxType="Warning">
      <p>{`The Brubeck milestone Broker Node is in Beta phase. Please be advised that there will be changes and assume that you need to upgrade your Broker Node before the Brubeck Network launch.`}</p>
    </Warning>
    <h1>{`Updating a Broker node`}</h1>
    <p>{`This article shows how to update your existing Streamr Broker node to the latest version. If you are installing your node for the first time, follow `}<Link to={docsLinks.installingABrokerNode} mdxType="Link">{`installing a Broker node`}</Link>{` instead.`}</p>
    <h2>{`Updating to the latest version`}</h2>
    <p>{`Updates become available for both the NPM and Docker versions of the Broker node software. As with the installation guide, there are two sets of instructions here, for Docker and NPM. Note that there is no need to run the config wizard again.`}</p>
    <h2>{`Updating a node running on Docker`}</h2>
    <p>{`Stop the currently running Broker as follows:`}</p>
    <ul>
      <li parentName="ul">{`Identify and record the container ID of your node by running `}<inlineCode parentName="li">{`docker ps`}</inlineCode></li>
      <li parentName="ul">{`Then, `}<inlineCode parentName="li">{`docker stop <container_ID>`}</inlineCode></li>
    </ul>
    <p>{`Next, pull the latest version of the Broker node software,`}</p>
    <pre><code parentName="pre" {...{}}>{`docker pull streamr/broker-node:31.0.0-beta.3
`}</code></pre>
    <p>{`Then start the container as usual - or refer to the `}<Link to={docsLinks.installingABrokerNode} mdxType="Link">{`installation guide`}</Link>{` for the start command.`}</p>
    <h2>{`Updating a node via NPM`}</h2>
    <ul>
      <li parentName="ul">{`Stop your existing broker node process (Ctrl+C for example)`}</li>
      <li parentName="ul">{`Then, `}<inlineCode parentName="li">{`npm install -g streamr-broker@31.0.0-beta.3`}</inlineCode></li>
    </ul>
    <p>{`Start the node using the `}<inlineCode parentName="p">{`streamr-broker`}</inlineCode>{` command as usual.`}</p>
    <p>{`If you have any problems updating with npm, you could try running `}<inlineCode parentName="p">{`npm doctor`}</inlineCode>{` to diagnose the symptoms.`}</p>
    <p>{`These instructions have been tested on Linux and macOS and may vary on Windows.`}</p>
    <h2>{`Automatic config migrations`}</h2>
    <p>{`There is an automatic migration functionality included which will convert your configuration file between Broker Node versions if needed.`}</p>
    <p>{`You can see if the migration happened when starting the node for the first time after the update:`}</p>
    <pre><code parentName="pre" {...{}}>{`Migrating config /path_to/broker-config.json to /path_to/config/default.json (archiving the original file to /path_to/broker-config.json.backup)
`}</code></pre>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;